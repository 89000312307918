import '../style/header.css'
import Logo from '../images/logo3.png'
import Photo from'../images/photo.webp'
import HeaderImg from'../images/headerhome.webp'
function Header() {
    return (
        <>
            <div className="container">
                <div style={{backgroundColor:'#424242'}}>
                <img alt="sfondo header cristofer web dev" src={HeaderImg} style={{ marginTop: '110px', left: '0px', right: '0px', boxShadow: '2px 1px 2px black', height:'60%', filter:'blur(3px)' }}></img>
                </div>
                <div className="centeredPhoto"><img src={Photo} style={{borderRadius:'50%'}} alt='Photo cristoferwebdev' ></img></div>               
                <div className="centered"><img src={Logo} className='logo' alt='logo cristofer russo'></img></div>               
            </div>
        </>
    )
}

export default Header;