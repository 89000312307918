import * as React from "react";
import AppBar from "@mui/material/AppBar";
import BoxNav from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Logo from "../images/logo3.png";
import Logo2 from "../images/logo4.png";
import { Link } from "react-router-dom";
import Cube from "./cube";
import MenuButton from "./menuButton";
import { ButtonGroup } from "@mui/material";
import Icon from "../images/whatapp.png";
import "../style/button.css";
function Navbar(props) {
  const drawerWidth = 200;
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const drawer = (
    <BoxNav
      onClick={handleDrawerToggle}
      sx={{
        textAlign: "center",
        alignItems: "center",
        background: "#1B4079",
        height: "100%",
      }}
    >
      <Typography variant="h6" sx={{ my: 2 }}>
        <a href="#home">
          <img
            alt="logo"
            src={Logo2}
            className="card-img-top"
            style={{ width: "4rem" }}
          ></img>
        </a>
      </Typography>
      <Divider />
      <div className="colorButton1">
        <MenuButton text="SERVIZI" location="#servizi" />
      </div>
      <div className="colorButton3">
        <MenuButton text="TECNOLOGIA" location="#tecnologie" />
      </div>
      <div className="colorButton2">
        <MenuButton text="SCRIVICI" location="#contact" />
      </div>
      <Link to="https://wa.me/+393421437909">
        <img
          src={Icon}
          alt="whatsapp cristofer web dev"
          style={{ width: "30px", margin: "20px" }}
        ></img>
      </Link>
    </BoxNav>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <BoxNav sx={{ display: "flex" }}>
      <AppBar component="nav" sx={{ background: "#1B4079" }}>
        <Toolbar>
          <IconButton
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" } }}
          >
            <Cube />
          </IconButton>
          <IconButton
            onClick={handleDrawerToggle}
            aria-controls="menu-appbar"
            aria-haspopup="true"
            sx={{ display: { sm: "none" } }}
          >
            <img
              src={Logo}
              style={{ width: "220px" }}
              alt="Logo cristofer web dev"
            ></img>
          </IconButton>
          <Typography
            variant="h5"
            component="div"
            sx={{
              flexGrow: 1,
              display: { xs: "none", sm: "block", textAlign: "left" },
            }}
          >
            <BoxNav>
              <a href="#home">
                <img
                  src={Logo}
                  className="card-img-top"
                  style={{ width: "16rem" }}
                ></img>
              </a>
            </BoxNav>
          </Typography>
          <BoxNav sx={{ display: { xs: "none", sm: "block" } }}>
            <ButtonGroup
              variant="filled"
              aria-label="outlined primary button group"
              sx={{ alignContent: "left" }}
            >
              <div className="colorButton1">
                <MenuButton text="SERVIZI" location="#servizi" />
              </div>
              <div className="colorButton3">
                <MenuButton text="TECNOLOGIA" location="#tecnologie" />
              </div>
              <div className="colorButton2">
                <MenuButton text="SCRIVICI" location="#contact" />
              </div>
            </ButtonGroup>
          </BoxNav>
        </Toolbar>
      </AppBar>
      <BoxNav component="nav">
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
      </BoxNav>
    </BoxNav>
  );
}

export default Navbar;
