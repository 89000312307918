import * as React from "react";
import "../style/footer.css";
import Fiver from "../images/fiverlogo.png";
import Linkedin from "../images/linkedinlogo.png";
import CV from "../images/cvlogo.png";

export default function Footer() {
  return (
    <body>
      <footer className="footer">
        <ul className="social-icon">
          <li className="social-icon__item">
            <a
              className="social-icon__link"
              href="https://it.fiverr.com/cristoferrusso?up_rollout=true"
            >
              <img src={Fiver} style={{ width: "50px" }} alt="Fiverr" />
            </a>
          </li>
          <li className="social-icon__item">
            <a
              className="social-icon__link"
              href="https://www.linkedin.com/in/cristofer-russo-541327201/"
            >
              <img src={Linkedin} style={{ width: "50px" }} alt="LinkedIn" />
            </a>
          </li>
          <li className="social-icon__item">
            <a
              className="social-icon__link"
              href="/assets/CV%20Russo%20Cristofer.pdf"
              download="CV_Cristofer_Russo.pdf"
            >
              <img src={CV} style={{ width: "50px" }} alt="CV" />
            </a>
          </li>
        </ul>
        <ul className="menu">
          <li>
            <div
              className="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4"
              style={{ color: "white", textAlign: "left" }}
            >
              <h6 className="text-uppercase fw-bold mb-4">
                CRISTOFER RUSSO | WEB DEVELOPER
              </h6>
              <p>
                Via Santa Cecilia – 24011 Almè (Bg)
                <br />
                Cell. +39 3421437909
                <br />
                cristofer.russo.dev@gmail.com
              </p>
            </div>
          </li>
        </ul>

        <ul className="policy-links">
          <li>
            <a
              href="https://www.iubenda.com/privacy-policy/51794341"
              className="iubenda-text iubenda-white iubenda-noiframe iubenda-embed"
              title="Privacy Policy"
            >
              Privacy Policy
            </a>
          </li>
          <li>
            <a
              href="https://www.iubenda.com/privacy-policy/51794341/cookie-policy"
              className="iubenda-text iubenda-white iubenda-noiframe iubenda-embed"
              title="Cookie Policy"
            >
              Cookie Policy
            </a>
          </li>
          <li>
            <a
              href="https://www.iubenda.com/termini-e-condizioni/51794341"
              className="iubenda-text iubenda-white iubenda-noiframe iubenda-embed"
              title="Termini e Condizioni"
            >
              Termini e Condizioni
            </a>
          </li>
        </ul>

        <script type="text/javascript">
          {`
            (function (w,d) {
              var loader = function () {
                var s = d.createElement("script"), tag = d.getElementsByTagName("script")[0];
                s.src = "https://cdn.iubenda.com/iubenda.js";
                tag.parentNode.insertBefore(s,tag);
              };
              if(w.addEventListener){
                w.addEventListener("load", loader, false);
              }else if(w.attachEvent){
                w.attachEvent("onload", loader);
              }else{
                w.onload = loader;
              }
            })(window, document);
          `}
        </script>
      </footer>
    </body>
  );
}
